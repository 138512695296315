html {
  background-color: white;
  scroll-padding-top: 160px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* font-family: Georgia, Times, "Times New Roman", serif; */
  font-family: "Cormorant Infant", Georgia, serif;
  color: #222;
  font-size: 16pt;

  margin: 0 !important;
  padding: 0 !important;
  background-color: white;
}

.content {
  margin-top: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
}
h1 {
  font-size: 60pt;
  font-weight: 300;
}
.title,
.title-sub {
  font-weight: 300;
  font-variant: all-small-caps;
  font-size: 60pt;
  white-space: nowrap;
}
.title-sub {
  padding-top: 30px;
  text-align: center;
  font-size: 20pt;
}
#header {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  z-index: 100;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid black;
  font-size: 60px;

  height: 230px;
  transition: 0.2s;
}
#header .title,
#header .title-sub {
  transition: 0.2s;
}
#header .title .fancy {
  text-transform: none;
  font-variant: normal;
  font-size: 110%;
}

#header.header-small {
  height: 150px;
}

#header.header-small .title {
  font-size: 50pt;
  margin-bottom: 10px;
  margin-top: -10px;
}
#header.header-small .title-sub {
  font-size: 15pt;
  padding-top: 10px;
}

/* Responsive header */
@media (max-width: 550px) {
  #header .title {
    font-size: 40pt;
    line-height: 50px;
    max-width: 90vw;
    margin-top: 20px;
  }
  #header.header-small .title {
    margin-top: 0px;
    font-size: 40pt;
  }
  body #header.header-small {
    height: 100px;
  }

  body #header {
    height: 160px;
  }
  body .App .content {
    margin-top: 160px;
  }
}
@media (max-width: 400px) {
  #header .title {
    font-size: 30pt;
  }
  #header.header-small .title {
    margin-top: 0px;
    font-size: 30pt;
  }
  body #header.header-small {
    height: 90px;
  }
  #header .hamburger {
    width: 30px;
  }

  body #header {
    height: 150px;
  }
  body .App .content {
    margin-top: 150px;
  }
}

nav,
.list-of-links {
  font-size: 14pt;
  margin-bottom: 20px;
  display: flex;
  gap: 40px;
}

a {
  text-decoration: underline;
  color: #555;
}
.list-of-links a,
nav a {
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  font-weight: bold;
}
nav .active {
  font-weight: bold;
}

.list-of-links {
  width: fit-content;
  margin: 30px 0;
  margin-left: auto;
  margin-right: auto;
}

.hamburger {
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: 20px;
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  /* will be shown by media query */
  display: none;
}
.hamburger-icon {
  fill: #333;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.behind-nav-tint {
  background-color: black;
  opacity: 0;
}

@media (max-width: 700px) {
  /* Use hamburger menu instead of nav bar */
  .hamburger {
    display: block;
  }

  nav {
    position: absolute;
    flex-direction: column;
    background-color: white;
    border: 1px solid black;
    z-index: 1000;
    padding: 10px;
    gap: 20px;
    left: 10px;
    top: 80px;
    /* transition: opacity 0.2s; */
  }
  nav.collapsed {
    opacity: 0;
    pointer-events: none;
  }

  .behind-nav-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }
  .behind-nav-tint.expanded {
    opacity: 0.2;
    pointer-events: all;
  }

  /* Resize header stuff now that we don't need nav bar  */
  #header {
    height: 190px;
  }
  #header.header-small {
    height: 120px;
  }
  .content {
    margin-top: 190px;
  }
}

/* Once we want to show the nav bar for everyone, remove this */
/* !!!!!!!!!!!!!!!!!! */
.hide-nav nav,
.hide-nav .hambuger,
.hide-nav .hamburger-icon {
  display: none !important;
}
.hide-nav #header {
  height: 190px;
}
.hide-nav .content {
  margin-top: 190px;
}
.hide-nav #header.header-small {
  height: 120px;
}
/* !!!!!!!!!!!!!!!!!! */

.hero-image img {
  display: block; /* prevents weird extra bottom margin */
  width: 100%;
  /* filter:grayscale(); */
  z-index: -10;
}
.black-and-white img {
  filter: grayscale();
}
.heroImageResponsive {
  background-image: url(../public/bridge_cc.png);
  width: 100%;
  height: 40vh;
  height: 70vh;
  background-position: center;
  background-position-y: 20%;
  background-size: cover;
}
@media (max-width: 600px) {
  .heroImageResponsive {
    height: 40vh;
  }
}

.accents {
  position: relative;
  width: 100%;
  /* overflow: hidden; */
}
.accents-responsive {
  transition: 0.2s;
  top: 0px;
}
@media (max-width: 600px) {
  .accents-responsive {
    top: 100px;
  }
}

.area {
  width: 100%;
  position: relative;
}

.section {
  text-align: center;
  z-index: 20;
  margin-top: 80px;
  margin-bottom: 80px;

  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.section-small {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 90vw;
}

.floral-accent {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
  pointer-events: none;
}
.floral-accent img {
  width: 35vw;
}
.floral-inline {
  position: static;
}
@media (max-width: 600px) {
  .floral-accent.grow img {
    width: 50vw;
  }
}

@media (min-width: 1700px) {
  .floral-accent img {
    max-width: 600px;
  }
}

.date {
  margin: 50px 0;
  margin-bottom: 90px;
  letter-spacing: 4px;
  color: #555;
  text-transform: uppercase;
}
.date div {
  margin: 40px 0;
}
.larger-date {
  font-size: 160%;
}
.th {
  font-size: 80%;
  letter-spacing: normal;
  vertical-align: top;
  margin-left: -4px;
  text-transform: none;
}

.fancy {
  font-weight: 400;
  font-family: "Qwitcher Grypen", cursive;
  letter-spacing: 0;

  /* font-size: 30pt; */
  font-size: 44pt;
  letter-spacing: 4.1px;
  line-height: 80%;
  margin: 20px 0;
}
.fancy em {
  font-size: 140%;
  font-style: normal;
}

.footer {
  content: " ";
  margin: 40px 0;
  text-align: center;
  width: 60vw;
  margin-bottom: 100px;
}

@media (max-width: 600px) {
  .footer {
    width: 80vw;
  }
}

.boxxy {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  padding: 40px 80px;
}

@media (max-width: 600px) {
  .content .boxxy {
    border: none;
    padding: 0px 0;
  }
}

.event {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 100px 0;
  margin-top: 50px;
  gap: 10px;
}
.event .left {
  border-right: 1px solid black;
}
.event .right,
.event .left {
  padding: 50px;
}

@media (max-width: 700px) {
  .event {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-bottom: 60px;
  }
  .event .right,
  .event .left {
    padding: 10px;
  }
  .event .left {
    border-right: none;
    /* border-bottom: 1px solid black; */
  }
}

.travel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.travel p {
  max-width: min(1000px, 90vw);
}
.clothes p {
  /* max-width: min(1000px, 90vw); */
  width: min(500px, 90vw);
}
.pickup p {
  max-width: min(1000px, 90vw);
}
.travel ol,
.travel ul {
  text-align: left;
}
.travel-main-content {
  flex-direction: column;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.events-calendar {
  display: flex;
  gap: 20px;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.calendar-square {
  width: 150px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}
.calendar-square div {
  margin-bottom: 12px;
}
.calendar-square-day {
  opacity: 0.9;
  display: flex;
  padding: 5px;
  justify-content: space-between;
}
.calendar-square a {
  text-decoration: none;
  color: black;
}
.calendar-square a:hover {
  text-decoration: underline;
}

.calendar-square-event {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 90%;
}

.calendar-square.calendar-square-after {
  border: 1px solid transparent;
  border-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, transparent 50%);
  border-image-slice: 1;
}

.calendar-square.calendar-square-before {
  border: 1px solid transparent;
  border-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.6) 0%, transparent 50%);
  border-image-slice: 1;
}

@media (max-width: 850px) {
  .calendar-square.calendar-square-after,
  .calendar-square.calendar-square-before {
    display: none;
  }
}
@media (max-width: 550px) {
  .events-calendar {
    flex-direction: column;
  }
}

.small-caps-title {
  text-transform: uppercase;
  font-size: 150%;
  opacity: 0.9;
  letter-spacing: 3px;
}
.travel .small-caps-title {
  margin-top: 20px;
}

.mini-small-caps {
  text-transform: uppercase;
  font-size: 90%;
  opacity: 0.9;
  letter-spacing: 1px;
}

.rsvp,
.pickup {
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
  margin-bottom: 100px;
}
.rsvp-name {
  font-size: 110%;
  font-weight: bold;
}
.rsvp-container,
.pickup-container {
  width: 100vw;
}
.big-checkmark {
  font-size: 130%;
  display: inline-block;
  margin: 10px;
}
.got-rsvp-message {
  font-size: 110%;
  font-weight: bold;
}
.rsvp-thank-you {
  margin-bottom: 140px;
  text-align: center;
}

.form-question {
  margin: 30px 0;
}
.form-question-title {
  font-size: 110%;
  font-weight: bold;
}

.form-byline {
  font-size: 80%;
  font-style: italic;
  margin-top: 5px;
  opacity: 0.9;
}
.form-required-star {
  vertical-align: middle;
  opacity: 0.7;
}

.button-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 10px 0;
}
button {
  margin: 10px;
  border: 1px solid black;
  border-radius: 2px;
  background-color: white;
  color: black;
  padding: 5px 20px;
  cursor: pointer;
}
button:hover,
button:focus {
  outline: 1px solid black;
  outline-offset: 2px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  outline-width: 0px;
}

input {
  border: 1px solid black;
  border-radius: 2px;
  padding: 5px 20px;
}
input:focus,
select:focus {
  outline: 1px solid black;
  outline-offset: 2px;
}

button,
input,
select {
  font-family: "Cormorant Infant", Georgia, serif;
  font-size: 105%;
}

.radio-option {
  margin: 10px;
  font-size: 110%;
  cursor: pointer;
  text-transform: capitalize;
}
.radio-option input,
.radio-option label {
  cursor: pointer;
}

.radio-option label {
  padding-right: 10px;
}

.radio-option:focus,
.radio-option:focus-within {
  outline: 1px solid black;
}
input[type="radio" i] {
  border-radius: 3px;
}
input[type="radio"]:focus {
  outline: none;
}

select {
  padding: 5px 20px;
  border: 1px solid black;
  border-radius: 2px;
  background-color: white;

  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 100% 50%;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline-width: 0;

  display: block;
}

.checkbox-option {
  display: flex;
  align-items: baseline;
  font-weight: bold;
  font-size: 110%;
}
input[type="checkbox"] {
  transform: scale(1.5);
}

.spinner {
  content: " ";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #000;
  border-left-color: white;
  animation: 1s infinite linear spin;
  position: absolute;
  right: -36px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  padding: 10px 20px;
  background-color: #ff4d6c;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  width: fit-content;
  margin: 10px 0;
  margin-left: auto;
  margin-right: auto;
}

.under-construction {
  padding: 30px 60px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background-color: #fffce5;
  border: 1px solid #e7e3c3;
  font-weight: bold;
}

.subline {
  font-size: 90%;
  font-style: italic;
  margin-top: 10px;
}
.quote {
  text-align: left;
}
.quote p {
  margin-left: 10px;
  margin-top: 5px;
}

.large-screen-only {
  display: block;
}
.small-screen-only {
  display: none;
}

@media (max-width: 700px) {
  .small-screen-only {
    display: block;
  }
  .large-screen-only {
    display: none;
  }

  .rsvp-thank-you {
    margin-bottom: 10px;
  }
}

/*
.image-mosaic {
  height: 420px;
}
.image-mosaic img {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}
.image-mosaic img:nth-child(1) {
  transform: rotate(-3deg) translate(0px, 50px);
}
.image-mosaic img:nth-child(2) {
  transform: rotate(4deg);
}
*/
.image-mosaic {
  display: flex;
  width: 90%;
  margin: 30px auto;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1000px;
}
.image-mosaic > div {
  flex: 1;
}
.image-mosaic > div > img {
  width: 100%;
  min-width: min(300px, 80vw);
}

.section-links {
  display: flex;
  flex-direction: column;
  position: relative;
}
.section-links a {
  text-decoration: none;
}

hr {
  width: 60%;
  margin: 30px;
  opacity: 0.5;
  margin-left: auto;
  margin-right: auto;
}

.expandable-title {
  font-size: 130%;
  text-transform: capitalize;
  /* font-weight: bold; */
  margin: 20px;
  cursor: pointer;
  text-align: left;
  user-select: none;
}

.reduced-width {
  width: 600px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 700px) {
  .reduced-width {
    width: 90vw;
  }
}

.expandable-small {
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 20px auto 30px auto;
}

.expandable-disclosure-triangle {
  font-size: 50%;
  vertical-align: middle;
  margin: 10px;
  display: inline-block;
  width: 10px;
}

@media (min-width: 700px) {
  form {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
.places-to-visit li {
  margin: 30px 10px;
}

.boy-girl-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  font-size: 120%;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.5);

  --switch-width: 40px;
  --switch-height: 20px;
  --switch-circle-r: 8px;
  --switch-pad: 2px;
}

.boy-girl-switch .switch {
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-width);
  border: calc(1 * var(--switch-pad)) solid black;
  position: relative;
}
.boy-girl-switch .switch:before {
  content: " ";
  display: inline-block;
  border: 4px solid black;
  border-radius: 50%;
  background-color: black;
  height: var(--switch-circle-r);
  width: var(--switch-circle-r);
  position: absolute;
  top: var(--switch-pad);
  left: var(--switch-pad);
  transition: transform 0.2s;
}
.boy-girl-switch .switch-highlight {
  text-decoration: underline;
  color: black;
}

.boy-girl-switch .switch-left:before {
  transform: translateX(0px);
}
.boy-girl-switch .switch-right:before {
  transform: translateX(
    calc(
      var(--switch-width) - 2 * var(--switch-pad) - 2 * var(--switch-circle-r)
    )
  );
}

.event-clothes-info {
  margin: 30px 0;
}

.typeOfClothing img {
  max-height: 400px;
  height: 100%;
}

.typeOfClothing div {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.leftright {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.leftright > * {
  max-width: 50%;
}

.mdashes-title {
  font-size: 200%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageset {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin: 20px;
  flex-wrap: wrap;
}

.clothes li {
  width: fit-content;
}

.event-clothes-info img {
  max-height: 400px;
  height: 100%;
}

.clothes .mdashes-title {
  margin-top: 40px;
}

.image-carosel {
  /* border: 1px solid black; */
  margin: 20px;
  max-width: 90vw;
  position: relative;
}

.image-carosel:before {
  content: " ";
  position: absolute;
  left: 0;
  height: 100%;
  background-color: white;
  width: 10px;
  z-index: 10;
  box-shadow: 0 0 20px 20px white;
}
.image-carosel:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  width: 10px;
  z-index: 10;
  box-shadow: 0 0 20px 20px white;
}

@media (min-width: 1300px) {
  .image-carosel:before,
  .image-carosel:after {
    box-shadow: none;
  }
}

.image-carosel-inner {
  --carosel-item-width: 200px;
  --carosel-gap: 10px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 300px;
  gap: var(--carosel-gap);
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.carosel-item {
  scroll-snap-align: center;
}
.carosel-item img {
  object-fit: cover;
}

/**********************/

.image-carosel2 {
  /* border: 1px solid black; */
  margin: 20px;
  max-width: 90vw;
  position: relative;
}

.image-carosel2-inner {
  --carosel2-item-width: 200px;
  --carosel2-gap: 5px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  /* width: calc(3 * var(--carosel2-item-width)); */
  height: 300px;
  gap: var(--carosel2-gap);
}

@media (max-width: 400px) {
  .carosel2-item:nth-child(5) {
    display: none;
  }
}

.carosel2-item {
  width: var(--carosel2-item-width);
  height: 100%;
}
.carosel2-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/**********************/

.pickup {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.pickup > p {
  width: max(60vw, 400px);
  margin: 10px 0;
}
.pickup .small-caps-title {
  margin-top: 60px;
}

.shuttles-grid {
  margin: 30px 0;
  display: grid;
  grid-template-columns: min-content 1fr min-content 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  align-items: center;
  gap: 4px;
}

.shuttles-grid > span:nth-child(5),
.shuttles-grid > span:nth-child(10),
.shuttles-grid > span:nth-child(15),
.shuttles-grid > span:nth-child(20) {
  background-color: #eee;
  font-size: 80%;
  border-radius: 8px;
  padding: 8px;
}

.small-links-list {
  /* width: min(500px, 90vw); */
  width: min(300px, 90vw);
  margin-left: auto;
  margin-right: auto;
}
.small-links-list ul {
  margin: 0;
}